import logo from './logo.svg';
import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <p className="company-name">Dew Drops</p>
        <div>
          <div className="comming-soon">blossoming soon</div>
          <div className="lds-heart">
            <div></div>
          </div>
        </div>
      </header>
    </div>
  );
}

export default App;
